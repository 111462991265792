import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@rsuite/icons'
import {
  FaExclamationCircle
} from 'react-icons/fa'

import { AdminDashboardInfo } from '../../types/dashboard'
import { AdminDashboardSection } from './AdminDashboardSection'

import './AdminDashboardCasual.css'

import { DashboardCarparkCard } from '../../components/DashboardCarparkCard'

/** Prop type for {@link AdminDashboard} */
type AdminDashboardProps =AdminDashboardInfo

const getStatus = (val: string) => (val === 'ACTIVE' ? 'ONLINE' : val)

const getOfflineCarparks = (data: any[] | undefined): string => {
  let cntOffline = 0
  data?.forEach((d: any) => {
    if (d.status === 'OFFLINE') {
      cntOffline += 1
    }
  })

  if (cntOffline > 1) {
    return `${cntOffline} carparks are offline`
  }
  if (cntOffline === 1) {
    return `${cntOffline} carpark is offline`
  }
  return 'All carparks are online'
}

/**
 * Customer dashboard which displays the profile status of the customer
 * and the customer's subscriptions & vehicles
 */
function AdminDashboardCasual (props: AdminDashboardProps):React.ReactElement {
  const {
    data,
  } = props

  const navigate = useNavigate()

  const locations = data ? [...data.location] : []
  locations.sort((a, b) => (getStatus(a.status) + a.location.name)
    .localeCompare(getStatus(b.status) + b.location.name))

  return (
    <div className="customer-dashboard">
      <div className="dashboard-banner">
        <div className="left">
          <div className="banner-description">
            { !getOfflineCarparks(data?.location).startsWith('All') ? (
              <div className="title">
                <Icon as={FaExclamationCircle} style={{ fontSize: '26px' }} />
                &nbsp;Carpark Status
              </div>
            ) : (
              <div className="title">
                Carpark Status
              </div>
            )}
            <div className="description">
              {getOfflineCarparks(data?.location)}
            </div>
          </div>
        </div>
      </div>
      <AdminDashboardSection
        title="Carparks"
        description="List of carparks with their current usage, capacity and status"
        sectionType="subscriptions"
      >
        {
          locations
            .map((e) => (
              <DashboardCarparkCard
                key={e.location.id}
                name={e.location.name}
                status={e.status}
                lastSync={e.lastSync}
                capacityAvailable={e.location.capacity.available}
                capacityTotal={e.location.capacity.total}
                occupancy={e.occupancy}
                location={e.location}
                navigate={navigate}
              />
            ))
        }
      </AdminDashboardSection>
    </div>
  )
}

export {
  AdminDashboardCasual
}
