import React, { ReactElement } from 'react'
import { ButtonToolbar, Button, IconButton } from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPen } from 'react-icons/fa'

import { InteractionCallback } from '../types/form'

import './PanelHeader.css'

type Props = {
  loading?: boolean,
  editing?: boolean,
  onEdit?: InteractionCallback,
  onCancel?: InteractionCallback,
  formId?: string,
  header: string,
  saveButtonText?: string,
  icon?: ReactElement,
  editDisabled?: boolean,
}

/**
 * Panel header component
 * @param {Props} props
 * @return {ReactElement}
 */
function PanelHeader (props: Props): ReactElement {
  const {
    loading = false,
    editing,
    onEdit, onCancel, formId, header, icon = <Icon as={FaPen} />, editDisabled, saveButtonText = 'Save',
  } = props
  return (
    <>
      <h2>{header}</h2>
      {editDisabled ? null : (
        <ButtonToolbar>
          {!editing ? (
            <IconButton
              circle
              appearance="subtle"
              icon={icon}
              onClick={onEdit}
            />
          ) : (
            <>
              {formId && !editDisabled && (
                <Button
                  appearance="primary"
                  type="submit"
                  form={formId}
                  loading={loading}
                >
                  {saveButtonText}
                </Button>
              )}
              {onCancel && (
                <Button
                  appearance="subtle"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              )}
            </>
          )}
        </ButtonToolbar>
      )}
    </>
  )
}

export {
  PanelHeader
}
