/* eslint-disable camelcase */
import { Amplify, Auth } from 'aws-amplify'

import { config } from '../config'
import { LoginStatus } from '../../types/enums'
import { mapAttributeNames } from './helpers'
import type {
  UserDetails, AuthUser, Credentials, GetCurrentUserOptions
} from '../../types/user'

Amplify.configure(config)

// Error response codes
const errors = {
  USER_NOT_CONFIRMED: 'UserNotConfirmedException',
}

/**
 * Create a new Cognito user
 * @param {UserDetails} details
 * @param {Credentials} credentials
 * @return {Promise<AuthUser>}
 */
const createUser = async (details: UserDetails, credentials: Credentials): Promise<AuthUser> => {
  const response = await Auth.signUp({
    username: credentials.username,
    password: credentials.password,
    attributes: {
      email: details.email,
      phone_number: details.phone,
      given_name: details.givenName,
      family_name: details.familyName,
    },
  })
  return {
    id: response.userSub,
    login: LoginStatus.UNCONFIRMED,
    emailVerified: false,
    roles: [],
  }
}

/**
 * Confirm a new Cognito user account
 * @param {string} username
 * @return {Promise}
 */
const confirmUser = async (username: string, code: string): Promise<void> => (
  Auth.confirmSignUp(username, code)
)

/**
 * Request a new account confirmation code
 * @param {string} username
 * @return {Promise}
 */
const resendConfirmationCode = async (username: string): Promise<void> => (
  Auth.resendSignUp(username)
)

/**
 * Authenticate an existing user
 * @param {string} username
 * @param {string} password
 * @return {Promise<AuthUser>}
 */
const authenticateUser = async (credentials: Credentials): Promise<AuthUser> => {
  const response = await Auth.signIn(credentials.username, credentials.password)
  return {
    id: response.username,
    login: LoginStatus.AUTHENTICATED,
    roles: response.signInUserSession.accessToken.payload['cognito:groups'],
    givenName: response.attributes.given_name,
    familyName: response.attributes.family_name,
    phone: response.attributes.phone_number,
    emailVerified: response.attributes.email_verified,
    email: response.attributes.email,
    adminOrganisationId: response.adminOrganisationId,
  }
}

/**
 * Get the current user from the Amplify session
 * @param {GetCurrentUserOptions} options - Options for this method
 * @return {Promise<AuthUser>}
 */
const getCurrentUser = async (options?: GetCurrentUserOptions): Promise<AuthUser> => {
  const response = await Auth.currentAuthenticatedUser({ bypassCache: options?.bypassCache ?? false })
  return {
    id: response.username,
    login: LoginStatus.AUTHENTICATED,
    roles: response.signInUserSession.accessToken.payload['cognito:groups'],
    email: response.attributes.email,
    givenName: response.attributes.given_name,
    familyName: response.attributes.family_name,
    emailVerified: response.attributes.email_verified,
    phone: response.attributes.phone_number,
    adminOrganisationId: response.attributes.adminOrganisationId,
  }
}

/**
 * Begins the email verification flow by sending the current user a code via email
 */
const startVerifyEmailFlow = async () => {
  await Auth.verifyCurrentUserAttribute('email')
}

/**
 * Confirms the email verification code by submitting the verification code
 * @param {String} code - The code recieved via email
 */
const submitEmailVerificationCode = async (code:string) => {
  await Auth.verifyCurrentUserAttributeSubmit('email', code)
}

/**
 * Request a password reset for a user
 * @param {string} username
 * @return {Promise}
 */
const resetPassword = async (username: string): Promise<void> => (
  Auth.forgotPassword(username)
)

/**
 * Set a new password for a user after initiating a reset
 * @param {string} username
 * @param {string} password
 * @param {string} code
 * @return {Promise}
 */
const setPassword = async (username: string, password: string, code: string): Promise<void> => (
  // The forgotPasswordSubmit incorrectly reports its resolve type as string
  Auth.forgotPasswordSubmit(username, code, password).then(() => {})
)

/**
 * Update attributes for the current user
 * @param {Object} user
 * @return {Promise}
 */
const updateUser = async (details: Partial<UserDetails>): Promise<void> => {
  const user = await Auth.currentAuthenticatedUser()
  await Auth.updateUserAttributes(user, mapAttributeNames(details))
}

/**
 * Clear the current user session
 * @return {Promise}
 */
const clearUser = async (): Promise<void> => (
  Auth.signOut()
)

export {
  errors,
  createUser,
  confirmUser,
  resendConfirmationCode,
  authenticateUser,
  getCurrentUser,
  resetPassword,
  setPassword,
  startVerifyEmailFlow,
  submitEmailVerificationCode,
  updateUser,
  clearUser
}
