import React, { ReactElement } from 'react'
import { Panel } from 'rsuite'
import { useNavigate } from 'react-router-dom'

import {
  usePageTitle, useUser
} from '../../app/hooks'
import { Subscription } from '../../types/subscription'
import { AdminSubscriptionTable } from './AdminSubscriptionsTable'
import { getAdminSubscriptions } from '../../services/graphql/queries'
import { SortOrder, SubscriptionStatus } from '../../types/enums'
import { usePaginatedApi } from '../../app/hooks/paginatedApi'

/**
 * An admin's view of subscriptions
 */
function AdminPendingSubscriptionList ():ReactElement {
  usePageTitle('Subscriptions')
  const navigate = useNavigate()
  const user = useUser()

  const pendingSubscriptionQuery = usePaginatedApi({
    query: getAdminSubscriptions,
    fetchParams: {
      params: {
        status: [SubscriptionStatus.PENDING],
        sortDate: SortOrder.DESC,
      },
    },
    itemsPerPage: 10,
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  const pendingSubscriptionsPage = pendingSubscriptionQuery.currentPage

  /**
   * Cb for when user clicks on the review button of a
   * pending subscription
   * @param {Subscription} subscription - the subscription object of the row that was clicked
   */
  const reviewCb = (subscription:Subscription) => {
    navigate(`/${user.getRoleForRoute()}/subscriptions/review/${subscription.id}`)
  }

  /**
   * Cb for when user clicks on the edit action for a subscription
   * @param {Subscription} subscription - The subscription to be edited
   */
  const editCb = (subscription:Subscription) => {
    navigate(`/${user.getRoleForRoute()}/subscriptions/edit/${subscription.id}`)
  }

  return (
    <div>
      <Panel header={<h2>Pending subscriptions</h2>}>
        <AdminSubscriptionTable
          subscriptions={pendingSubscriptionsPage || []}
          loading={pendingSubscriptionQuery.loading}
          reviewCb={reviewCb}
          nextPage={pendingSubscriptionQuery.next || undefined}
          prevPage={pendingSubscriptionQuery.prev || undefined}
          editCb={editCb}
        />
      </Panel>
    </div>
  )
}

export {
  AdminPendingSubscriptionList
}
