import React from 'react'

import { Link } from 'react-router-dom'

import {
  DashboardNewItem, DashboardProfileCard, DashboardSubscriptionItem, DashboardVehicleItem
} from '../../components'
import { CustomerDashboardInfo } from '../../types/dashboard'
import { CustomerDashboardBanner } from './CustomerDashboardBanner'
import { CustomerDashboardSection } from './CustomerDashboardSection'
import './CustomerDashboardMember.css'

/** Prop type for {@link CustomerDashboard} */
type CustomerDashboardProps = CustomerDashboardInfo

/**
 * Customer dashboard for members which displays the profile status of the customer
 * and the customer's subscriptions & vehicles
 */
function CustomerDashboardMember (props: CustomerDashboardProps):React.ReactElement {
  const {
    showSubscriptionHeader,
    casualDashboard,
    vehicles,
    subscriptions,
    vehiclesActive,
    subscriptionsActive,
    paymentMethodExists,
    addPaymentMethodCb,
    addSubscriptionCb,
    addVehicleCb,
    vehicleIcon,
    subscriptionIcon,
    paymentIcon,
  } = props

  return (
    <div className="customer-dashboard">
      <div className="casualbanner">
        <div className="casual-parking-banner">
          <div className="post">
            <img alt="parking-sign" src="/images/parking-sign.svg" />
          </div>
          <span className="title">
            Casual Parking memberships available soon!
          </span>
          <span className="alternative">
            Subscribe for Permanent Parking
            {' '}
            <Link to="/subscriptions"> here </Link>
          </span>
        </div>
      </div>
      <CustomerDashboardBanner
        showSubscriptionHeader={showSubscriptionHeader}
        casualDashboard={casualDashboard}
        vehiclesActive={vehiclesActive}
        subscriptionsActive={subscriptionsActive}
        paymentMethodExists={paymentMethodExists}
        addPaymentMethodCb={addPaymentMethodCb}
        addSubscriptionCb={addSubscriptionCb}
        addVehicleCb={addVehicleCb}
        vehicleIcon={vehicleIcon}
        subscriptionIcon={subscriptionIcon}
        paymentIcon={paymentIcon}
      />
      <CustomerDashboardSection
        title="Your profile"
        description={
          'Add or update vehicle details, change payment details and'
        + ' manage subscriptions for a better parking experience'
        }
        sectionType="profile"
        manageRoute=""
      >
        <DashboardProfileCard
          title="1. Payment method"
          ctaLabel="Add payment method"
          ctaIcon={paymentIcon}
          description="Add a payment method for your account"
          ctaAction={addPaymentMethodCb}
          complete={paymentMethodExists}
        />
        <DashboardProfileCard
          title="2. Carpark subscription"
          ctaLabel="Add a subscription"
          complete={subscriptionsActive}
          ctaIcon={subscriptionIcon}
          description="Subscribe to your carpark of choice for unlimited access"
          ctaAction={addSubscriptionCb}
        />
        <DashboardProfileCard
          title="3. Vehicles"
          ctaLabel="Add a vehicle"
          ctaIcon={vehicleIcon}
          description="Add any number of vehicles to use with your car park subscriptions"
          ctaAction={addVehicleCb}
          complete={vehiclesActive}
        />
      </CustomerDashboardSection>
      <CustomerDashboardSection
        sectionType="subscriptions"
        manageRoute={subscriptions.manageRoute}
        title={subscriptions.title}
        description={subscriptions.description}
      >
        {
          subscriptions.data.map((e) => (
            <DashboardSubscriptionItem
              key={e.id}
              locationName={e.location.name}
              locationAddress={e.location.address}
              status={e.status}
            />
          ))
        }
        <DashboardNewItem
          description={subscriptions.addItemDescription}
          ctaIcon={subscriptions.itemIcon}
          addItemCb={subscriptions.addItemCb}
          ctaLabel={subscriptions.addItemLabel}
        />
      </CustomerDashboardSection>
      <CustomerDashboardSection
        title={vehicles.title}
        description={vehicles.description}
        sectionType="vehicles"
        manageRoute={vehicles.manageRoute}
      >
        {
          vehicles.data.map((e) => (
            <DashboardVehicleItem
              key={e.id}
              vehicleMake={e.make}
              vehicleModel={e.model}
              vehicleRego={e.rego}
            />
          ))
        }
        <DashboardNewItem
          description={vehicles.addItemDescription}
          ctaIcon={vehicles.itemIcon}
          addItemCb={vehicles.addItemCb}
          ctaLabel={vehicles.addItemLabel}
        />
      </CustomerDashboardSection>
    </div>
  )
}

export {
  CustomerDashboardMember
}
