import React, { ReactElement } from 'react'
import {
  Container,
  Header,
  Footer
} from 'rsuite'

import { HeaderBar } from '../features/navigation/HeaderBar'
import { AlertContainer } from '../features/alerts/AlertContainer'
import { useSelector, useDispatch } from '../app/hooks'
import { userActions, userSelectors } from '../store/user/slice'

type Props = {
  children: React.ReactNode,
  header?: boolean,
  noAlerts?: boolean,
}

/**
 * Base layout with header and footer
 * @param {Props} props
 * @return {ReactElement}
 */
function BaseLayout (props: Props): ReactElement {
  const dispatch = useDispatch()
  const user = useSelector(userSelectors.getUser)
  const {
    children, header = true, noAlerts = false,
  } = props

  const logout = () => (
    dispatch(userActions.logout())
  )

  return (
    <main>
      {!noAlerts && <AlertContainer fixed />}
      <Container>
        {header && (
          <Header>
            <HeaderBar
              user={user}
              onLogout={logout}
            />
          </Header>
        )}
        {children}
        <Footer />
      </Container>
    </main>
  )
}

export {
  BaseLayout
}
