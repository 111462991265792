import { RootState } from '../../app/store'
import type { KeyedAlert } from '../../types/alert'

const getAlerts = (state: RootState): Array<KeyedAlert> => (
  state.alerts.alerts
)

export {
  getAlerts
}
