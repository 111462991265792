import React, { ReactElement } from 'react'
import { Panel } from 'rsuite'
import { useNavigate } from 'react-router-dom'

import {
  usePageTitle, useUser
} from '../../app/hooks'
import { Subscription } from '../../types/subscription'
import { getAdminSubscriptions } from '../../services/graphql/queries'
import { SortOrder, SubscriptionStatus } from '../../types/enums'
import { usePaginatedApi } from '../../app/hooks/paginatedApi'
import { AdminModifiedSubscriptionTable } from './AdminModifiedSubscriptionsTable'

/**
 * An admin's view of subscriptions
 */
function AdminModifiedSubscriptionList ():ReactElement {
  usePageTitle('Subscriptions')
  const navigate = useNavigate()
  const user = useUser()

  const modifiedSubscriptionQuery = usePaginatedApi({
    query: getAdminSubscriptions,
    fetchParams: {
      params: {
        status: [SubscriptionStatus.AMEND],
        sortDate: SortOrder.DESC,
      },
    },
    itemsPerPage: 10,
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  const modifiedSubscriptionsPage = (modifiedSubscriptionQuery?.currentPage ?? []).map((sub) => {
    const request = JSON.parse(sub.modificationRequest)
    return {
      ...sub,
      requestDate: request.requestDate,
      currentVehicles: sub.vehicles,
      requestedVehicles: request.vehicles,
    }
  })

  /**
   * Cb for when user clicks on the review button of a
   * amended subscription
   * @param {Subscription} subscription - the subscription object of the row that was clicked
   */
  const reviewCb = (subscription:Subscription) => {
    navigate(`/${user.getRoleForRoute()}/subscriptions/modified/${subscription.id}`)
  }

  return (
    <div>
      <Panel header={<h2>Amended subscriptions</h2>}>
        <AdminModifiedSubscriptionTable
          subscriptions={modifiedSubscriptionsPage || []}
          loading={modifiedSubscriptionQuery.loading}
          reviewCb={reviewCb}
          nextPage={modifiedSubscriptionQuery.next || undefined}
          prevPage={modifiedSubscriptionQuery.prev || undefined}
          editCb={() => {}}
        />
      </Panel>
    </div>
  )
}

export {
  AdminModifiedSubscriptionList
}
