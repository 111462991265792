import React, { ReactElement } from 'react'
import { Message } from 'rsuite'

import type { Alert as AlertProps, CloseCallback } from '../../types/alert'

const ALERT_TIMEOUT = 10000 // milliseconds

type Props = AlertProps & {
  onClose: CloseCallback,
}

/**
 * A notification message
 * @param {Props} props
 * @return ReactElement
 */
function Alert (props: Props): ReactElement {
  const {
    type,
    message,
    title,
    trusted = false,
    persist,
    onClose,
  } = props

  let content = <p>{message}</p>
  if (trusted) {
    /* eslint-disable-next-line react/no-danger */
    content = <p dangerouslySetInnerHTML={{ __html: message ?? '' }} />
  }

  return (
    <Message
      type={type}
      header={title}
      duration={persist ? 0 : ALERT_TIMEOUT}
      onClose={onClose}
      closable
      showIcon
    >
      {content}
    </Message>
  )
}

export {
  Alert
}
