import React from 'react'
import {
  Field, useField
} from 'formik'
import {
  Form as RForm,
  Radio, Panel
} from 'rsuite'
import {
  CheckPicker, DateRangePicker, RadioGroup
} from '@rsuite/formik'

import FieldWithFormik from '../formik-fields/FieldWithFormik'
import { DatePicker } from '../formik-fields'

const START_OF_DAY = new Date()
const END_OF_DAY = new Date()
START_OF_DAY.setHours(0, 0, 0, 0)
END_OF_DAY.setHours(23, 59, 59, 0)

const DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
].map((item) => ({ label: item, value: item }))

export function TariffDatePage ({ form }: { form: any }) {
  const [tariffDateTypeField] = useField('tariffDateType')
  const radioHandler = () => {
    form.setFieldValue('days', [])
    form.setFieldValue('date', null)
  }
  const { value } = tariffDateTypeField
  let tariffDateField = null
  if (value === 'date') {
    tariffDateField = (
      <FieldWithFormik
        required
        errors={form.errors}
        touched={form.touched}
        name="date"
        label="Select the date:"
        component={DatePicker}
        placeholder="Select Date"
        oneTap
      />
    )
  } else {
    tariffDateField = (
      <FieldWithFormik
        required
        errors={form.errors}
        touched={form.touched}
        name="days"
        label="Select the days:"
        data={DAYS_OF_WEEK}
        component={CheckPicker}
        searchable={false}
      />
    )
  }

  return (
    <>
      <Panel header="Apply to days or a date">
        <p>
          The tariff will apply to the days of the week or a specific date:
        </p>
        <Field name="tariffDateType" component={RadioGroup} onChange={radioHandler}>
          <Radio value="days">Days</Radio>
          <Radio value="date">Date</Radio>
        </Field>
        {tariffDateField}
      </Panel>
      <Panel header="Set hours">
        <FieldWithFormik
          required
          errors={form.errors}
          touched={form.touched}
          label="Hours tariff is active:"
          name="hours"
          format="HH:mm"
          character=" - "
          placeholder="Select Time Range"
          placement="auto"
          ranges={[]}
          defaultCalendarValue={[START_OF_DAY, END_OF_DAY]}
          component={DateRangePicker}
        />
      </Panel>
      <Panel header="Set entry and exit conditions">
        <RForm.ControlLabel htmlFor="entryCondition" className="rs-form-control-label">
          Must enter between:
        </RForm.ControlLabel>
        <Field
          id="entryHours"
          name="entryHours"
          format="HH:mm"
          character=" - "
          placeholder="Select Time Range"
          placement="auto"
          ranges={[]}
          component={DateRangePicker}
        />
        <RForm.ControlLabel htmlFor="entryCondition" className="rs-form-control-label">
          Must exit between:
        </RForm.ControlLabel>
        <Field
          id="exitHours"
          name="exitHours"
          format="HH:mm"
          character=" - "
          placeholder="Select Time Range"
          placement="auto"
          ranges={[]}
          component={DateRangePicker}
        />
      </Panel>
    </>
  )
}
