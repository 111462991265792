import React from 'react'
import {
  Modal, Button, ButtonToolbar, Input
} from 'rsuite'

import { SubscriptionDTO } from '../../types/subscription'

type Props = {
  onConfirm: (vehicles: number) => void,
  onClose: () => void,
  subscription?: SubscriptionDTO,
  saving: boolean,
}

/**
 * Modal component displayed when user tries to
 * subscribe without any payment methods
 * @param {Props} props
 * @param {boolean} props.open - Whether this modal is opened or not
 * @param {Function} props.onClose - Cb called when modal is closed
 * @param {Function} props.onOk - Cb called when user clicks ok
 */
function SubscriptionModifyModal (props: Props) {
  const {
    onConfirm, onClose, subscription, saving,
  } = props
  const [vehicles, setVehicles] = React.useState(subscription?.vehicles ?? 0)
  const locationName = subscription?.location?.name

  const disableButton = vehicles === subscription?.vehicles || vehicles < 1

  let message = 'Please enter the new number of vehicles you would like on your subscription.'
  if (subscription && subscription.vehicles) {
    if (vehicles > subscription.vehicles) {
      message = `
      Your subscription will increase from ${subscription.vehicles}
      to ${vehicles} vehicles at ${locationName}. Due to limited car park spaces, your
      request may not be approved straight away, however upon approval, the changes will take effect
      immediately, and a pro rata charge will be applied.
      `
    } else if (vehicles < subscription.vehicles) {
      message = `
      Your subscription will decrease from ${subscription.vehicles}
      to ${vehicles} vehicles at ${locationName}. This will take effect at the start
      of your next billing cycle.
      `
    } else if (vehicles === subscription.vehicles) {
      message = `
      Please select a different number of vehicles to your current subscription to amend your subscription.
      `
    }
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>Are you sure you want to amend your subscription?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {message}
        </p>

        <Input
          type="number"
          defaultValue={vehicles}
          onChange={(value) => setVehicles(Number.parseInt(value, 10))}
          min="1"
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button
            onClick={() => onConfirm(vehicles)}
            appearance="primary"
            loading={saving}
            disabled={disableButton}
          >
            Amend my subscription
          </Button>
          <Button onClick={onClose} appearance="subtle">Cancel</Button>
        </ButtonToolbar>
      </Modal.Footer>
    </>
  )
}

export {
  SubscriptionModifyModal
}
