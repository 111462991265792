import { PayloadAction } from '@reduxjs/toolkit'

import { AlertState } from './slice'
import type { Alert, KeyedAlert } from '../../types/alert'

/**
 * Get a unique key for an alert
 * @param {Alert} alert
 * @return {string}
 */
const key = (alert: Alert): string => (
  alert.key || `${alert.type}:${alert.message}`
)

const pushAlert = (state: AlertState, action: PayloadAction<Alert>): void => {
  const alert = action.payload
  // Prevent alerts with explicit keys from repeating
  if (alert.key) {
    if (!state.previousKeys.includes(alert.key)) {
      state.alerts.push(alert as KeyedAlert)
      state.previousKeys.push(alert.key)
    }
  // Prevent duplicate alerts from showing simultaneously
  } else if (!state.alerts.find((item) => key(item) === key(alert))) {
    state.alerts.push({ key: key(alert), ...alert })
  }
}

const removeAlert = (state: AlertState, action: PayloadAction<string>): void => {
  state.alerts = state.alerts.filter((alert) => alert.key !== action.payload)
}

export {
  pushAlert,
  removeAlert
}
