/* eslint-disable camelcase */

// AWS Amplify configuration for user system (Cognito) and GraphQL
const config = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEBCLIENT_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOL_ID,
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_TICKETLESS_API_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: 'AWS_IAM',
}

export {
  config
}
