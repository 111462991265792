import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import { userReducer } from '../store/user/slice'
import { apiReducer } from '../store/api-data/slice'
import { alertReducer } from '../store/alerts/slice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    api: apiReducer,
    alerts: alertReducer,
  },
})

/* eslint-disable @typescript-eslint/indent */
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
