import React, { ReactElement } from 'react'
import humanize from 'humanize-plus'
import { Link, useNavigate } from 'react-router-dom'
import {
  Panel,
  IconButton,
  ButtonToolbar,
  Table,
  Tag,
  TagGroup,
  Form,
  ButtonGroup,
  Button
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus } from 'react-icons/fa'

import { usePageTitle, usePaginatedApi } from '../../app/hooks'
import { getAdminLocations } from '../../services/graphql/queries'
import { ActionMenu, TableData } from '../../components'
import type { RowData } from '../../types/table'
import './LocationList.css'
import { locationIsCarpark } from '../../types/locationHelpers'

/**
 * location list component
 * @return {ReactElement}
 */
function LocationList (): ReactElement {
  const navigate = useNavigate()

  usePageTitle('Locations')

  const api = usePaginatedApi({
    query: getAdminLocations,
    itemsPerPage: 20,
    fetchParams: {
      organisation: process.env.REACT_APP_ORGANISATION_ID || '',
    },
    queryOptions: {
      cleanUpOnDismount: true,
      displayErrorAlerts: false,
    },
  })

  /**
   * Render a name cell for a single location
   * @param {Location} location
   * @return {ReactElement}
   */
  const renderName = (location: RowData): ReactElement => (
    <Link to={location.id}>{location.name}</Link>
  )

  /**
   * Render a status cell for a single location
   * @param {Location} location
   * @return {ReactElement}
   */
  const renderStatus = (location: RowData): ReactElement => (
    <TagGroup>
      <Tag>{location.status}</Tag>
    </TagGroup>
  )

  /**
   * Render a occupancy cell for a single location
   * @param {Location} location
   * @return {ReactElement}
   */
  const renderOccupancy = (location: RowData): ReactElement => {
    if (!locationIsCarpark(location)) {
      return <span>-</span>
    }
    const { total, available } = location.capacity
    const used = total - available
    const occupancy = total > 0 ? (used / total) * 100 : 100

    if (occupancy > 95) {
      return (
        <Tag color="red" className="location-occupancy-tag">
          {`${Math.round(occupancy)}%`}
        </Tag>
      )
    }
    if (occupancy > 75) {
      return (
        <Tag color="orange" className="location-occupancy-tag">
          {`${Math.round(occupancy)}%`}
        </Tag>
      )
    }

    return (
      <Tag color="green" className="location-occupancy-tag">
        {`${Math.round(occupancy)}%`}
      </Tag>
    )
  }

  /**
   * Render a total capacity cell for a single location
   * @param {Location} location
   * @return {ReactElement}
   */
  const renderTotalCapacity = (location: RowData): ReactElement => {
    if (!locationIsCarpark(location)) {
      return <span>-</span>
    }
    const { total } = location.capacity
    return (
      <span>
        {`${humanize.formatNumber(total, 0)}`}
      </span>
    )
  }

  /**
   * Render a used capacity cell for a single location
   * @param {Location} location
   * @return {ReactElement}
   */
  const renderUsedCapacity = (location: RowData): ReactElement => {
    if (!locationIsCarpark(location)) {
      return <span>-</span>
    }
    const { total, available } = location.capacity
    const used = total - available
    return (
      <span>
        {`${humanize.formatNumber(used, 0)}`}
      </span>
    )
  }

  /**
   * Dropdown menu for a row in the location list table
   * @param {RowData} location - The location object of the row
   * @return {ReactElement}
   */
  const renderActions = (location: RowData): ReactElement => {
    const actions = []
    actions.push({ label: 'View occupancy', action: () => navigate(`${location.id}/occupancy`) })
    return (
      <ActionMenu actions={actions} />
    )
  }

  return (
    <Panel
      header={(
        <>
          <h2>Car parks</h2>
          <ButtonToolbar>
            <IconButton
              appearance="subtle"
              icon={<Icon as={FaPlus} />}
              as={Link}
              to="new"
            />
          </ButtonToolbar>
        </>
      )}
      className="location-list"
    >
      <Table data={api.currentPage || []} loading={api.loading} autoHeight>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Location name</Table.HeaderCell>
          <TableData dataKey="name" content={renderName} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <TableData dataKey="status" content={renderStatus} />
        </Table.Column>
        <Table.Column flexGrow={0.5}>
          <Table.HeaderCell>
            Occupancy
            <Form.HelpText tooltip>
              The proportion of parking spaces that are currently occupied
            </Form.HelpText>
          </Table.HeaderCell>
          <TableData dataKey="occupancy" content={renderOccupancy} />
        </Table.Column>
        <Table.Column flexGrow={0.5}>
          <Table.HeaderCell>Capacity</Table.HeaderCell>
          <TableData dataKey="total" content={renderTotalCapacity} />
        </Table.Column>
        <Table.Column flexGrow={0.5}>
          <Table.HeaderCell>Used</Table.HeaderCell>
          <TableData dataKey="used" content={renderUsedCapacity} />
        </Table.Column>
        <Table.Column flexGrow={0.5}>
          <Table.HeaderCell>Actions</Table.HeaderCell>
          <TableData dataKey="action" content={renderActions} />
        </Table.Column>
      </Table>
      <div className="pagination">
        <ButtonGroup>
          <Button disabled={!api.prev} onClick={() => api.prev?.()}>
            Prev
          </Button>
          <Button disabled={!api.next} onClick={() => api.next?.()}>
            Next
          </Button>
        </ButtonGroup>
      </div>
    </Panel>
  )
}

export {
  LocationList
}
