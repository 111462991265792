import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useUser, useApi, useAlert } from '../../app/hooks'
import { getOutstandingPayments } from '../../services/graphql/queries'

import { AlertType } from '../../types/enums'

/**
 * Payment reminder alert trigger component
 * Checks for outstanding payments on the API and triggers an alert if any are found
 * @return {ReactElement}
 */
function PaymentReminder (): null {
  const user = useUser()
  const location = useLocation()

  const paymentQuery = useApi(getOutstandingPayments)
  const pushAlert = useAlert()

  useEffect(() => {
    paymentQuery.sendRequest({ userId: user.id })
  }, [])

  const payments = paymentQuery.getResponse().data || []
  useEffect(() => {
    if (
      !location.pathname.startsWith('/payments/outstanding')
      && payments.length > 0
    ) {
      const message = `You have ${payments.length} outstanding payment${payments.length === 1 ? '' : 's'}
        on your account. You may not be able to park with Noover until this has been resolved.
        <a href="/payments/outstanding">Click here to pay now.</a>`

      pushAlert({
        type: AlertType.WARNING,
        title: 'Car park access suspended',
        message,
        trusted: true,
        persist: true,
      })
    }
  }, [payments])

  return null
}

export {
  PaymentReminder
}
