import React from 'react'
import { Icon } from '@rsuite/icons'
import FileDownloadIcon from '@rsuite/icons/FileDownload'

// TODO: Move all CSV functionality into it's own component with next Report
function tableToCSV (columns: Array<{ key: String, label: String }>, data: Array<any>) {
  const blob = []
  blob.push(columns.map((c) => c.label).join())
  data.forEach((row) => {
    blob.push('\n')
    const orderedData: any[] = []
    columns.forEach((column) => orderedData.push(row[column.key as keyof typeof row] || 'null'))
    blob.push(orderedData.join())
  })
  return blob
}

function DownloadTableToCsv ({
  url, fileName, text, style,
}: {url:any, fileName:any, text: String, style?: any}) {
  return (
    <a href={url} download={fileName} style={{ color: 'black', ...style }}>
      {text}
      {' '}
      <Icon as={FileDownloadIcon} height={18} width={18} fill="black" />
    </a>
  )
}

export {
  DownloadTableToCsv,
  tableToCSV
}
