import React, { ReactElement, ReactNode } from 'react'
import { Sidenav, Nav } from 'rsuite'

import { NooverLogo } from '../../components'
import { SideMenuToggle } from './SideMenuToggle'
import type { InteractionCallback } from '../../types/form'
import { VersionInfo } from './VersionInfo'
import { ShowWithRole } from '../access/ShowWithRole'
import { UserRole } from '../../types/enums'

import './SideMenu.css'

type Props = {
  onClose: InteractionCallback,
  children: ReactNode,
}

/**
 * Sidebar menu component
 * @param {Props} props
 * @return {ReactElement}
 */
function SideMenu (props: Props): ReactElement {
  const { children, onClose } = props

  return (
    <Sidenav className="side-menu">
      <Sidenav.Body>
        <a href="https://noover.com" aria-label="Noover home"><NooverLogo /></a>
        <SideMenuToggle mode="close" onClick={onClose} />
        <Nav>
          {children}
        </Nav>
      </Sidenav.Body>
      <ShowWithRole role={UserRole.ADMIN}>
        <VersionInfo />
      </ShowWithRole>
    </Sidenav>
  )
}

export {
  SideMenu
}
