import React from 'react'
import { Field, useField } from 'formik'
import { Panel, Radio } from 'rsuite'
import { InputNumber, RadioGroup } from '@rsuite/formik'

import FieldWithFormik from '../formik-fields/FieldWithFormik'
import Table from './IncrementalRateTable'

export function TariffRatesPage ({ form }: { form: any }) {
  let field = null
  const radioHandler = () => {
    form.setFieldValue('incrementalRate', [])
    form.setFieldValue('fixedGrace', '')
    form.setFieldValue('fixedFee', '')
  }
  const [tariffRateTypeField] = useField('tariffRateType')
  const { value } = tariffRateTypeField

  if (value === 'incremental') {
    field = (
      <Panel header="Set incremental rates">
        <FieldWithFormik
          required
          errors={form.errors}
          touched={form.touched}
          name="incrementalRate"
          label="Tariff rates:"
          component={Table}
        />
      </Panel>
    )
  }
  if (value === 'fixed') {
    field = (
      <Panel header="Set fixed rates">
        <FieldWithFormik
          required
          errors={form.errors}
          touched={form.touched}
          name="fixedFees"
          label="Tariff Fee:"
          component={InputNumber}
          prefix="$"
        />

        <FieldWithFormik
          required
          errors={form.errors}
          touched={form.touched}
          name="fixedGrace"
          label="Grace period in minutes:"
          component={InputNumber}
          postfix="mins"
        />
      </Panel>
    )
  }

  return (
    <>
      <Panel header="Set tariff type">
        <p>
          Define the rate type for the tariff:
        </p>
        <Field name="tariffRateType" component={RadioGroup} onChange={radioHandler}>
          <Radio value="incremental">Incremental</Radio>
          <Radio value="fixed">Fixed</Radio>
        </Field>
      </Panel>
      {field}
    </>
  )
}
