import { Schema } from 'rsuite'

import { AlertType } from '../../types/enums'
import type { FormError, FieldSpec } from '../../types/form'
import type { UserError } from '../../types/user'
import type { ApiError } from '../../types/api'
import type { UserErrorCode, ApiErrorCode } from '../../types/enums'

class Validator {
  model: any // Should be Schema.Model

  errorCodes: Record<string, Array<UserErrorCode|ApiErrorCode>>

  unusedErrors: Array<UserError|ApiError> = []

  /**
   * Create a new validator
   * @param {Object} fields - An object describing how fields should be validated
   */
  constructor (fields: Record<string, FieldSpec>) {
    const models: Record<string, any> = {}
    const errors: Record<string, any> = {}
    Object.entries(fields).forEach(([name, spec]) => {
      if (spec.model) {
        models[name] = spec.model
      }
      if (spec.errors) {
        errors[name] = spec.errors
      }
    })
    this.model = Schema.Model(models)
    this.errorCodes = errors
  }

  /**
   * Parse an error so it can be used in a form
   * @param {userError|ApiError|null} error
   * @return {FormError}
   */
  parseError (error: UserError|ApiError|null): FormError {
    const formError: FormError = {}
    if (!error) {
      return formError
    }
    let unusedErrors = [...this.unusedErrors, error]
    Object.entries(this.errorCodes).forEach(([field, codes]) => {
      if (codes.includes(error.code)) {
        formError[field] = error.message.replace(/\.$/, '')
        unusedErrors = unusedErrors.filter(({ code }) => code !== error.code)
      }
    })
    this.unusedErrors = unusedErrors
    return formError
  }

  /**
   * Show any unused errors in a generic alert
   * @param {function} pushAlert - A function to call to create the alert
   */
  emitUnusedErrors (pushAlert: Function) {
    this.unusedErrors.forEach((error) => {
      pushAlert({ type: AlertType.ERROR, message: error.message, key: error.requestId })
    })
  }
}

export {
  Validator
}
