import React, { ReactElement, useEffect } from 'react'
import { Panel } from 'rsuite'

import { LocationForm } from '../../forms/LocationForm'
import { Location, LocationDetailsFormData, UpdateLocationParams } from '../../types/location'

import { PanelHeader } from '../../components'
import { useApi, useFormState, usePageTitle } from '../../app/hooks'
import { updateLocation } from '../../services/graphql/queries'

type Props = {
  onFinish : () => void
  location : Location
}

/**
 * Component to edit a location
 * @param {Props} props
 * @param {() => void} props.onFinish - Cb when editing is finished
 * @param {Location} props.location - Location object to edit
 * @returns {ReactElement}
 */
function LocationEdit (props:Props): ReactElement {
  const { onFinish, location } = props
  const features = location.featureDetails
    .filter((item) => item.enabled)
    .map((item) => item.key)

  const editLocationApi = useApi(updateLocation)
  const editResponse = editLocationApi.getResponse()
  const responseData = editResponse.data
  const isLoading = editResponse.loading
  const { error } = editResponse

  usePageTitle('Edit car park')

  useEffect(() => {
    if (responseData !== null) {
      onFinish()
    }
    return editLocationApi.cleanup
  }, [responseData])

  const [formData, setField] = useFormState<LocationDetailsFormData>({
    name: location.name,
    description: location.description,
    organisation: location.organisation.id,
    address: location.address,
    timezone: location.timezone,
    type: location.type,
    status: location.status,
    features,
    controllers: location.controllers,
    lat: location.geolocation.lat,
    lon: location.geolocation.lon,
    totalCapacity: location.capacity.total,
    availableCapacity: location.capacity.available,
  })

  const onFormSubmit = () => {
    const requestParams: UpdateLocationParams = {
      locationId: location.id,
      name: formData.name,
      description: formData.description,
      controllers: formData.controllers,
      status: formData.status,
      timezone: formData.timezone,
      address: formData.address,
      geolocation: {
        lat: formData.lat,
        lon: formData.lon,
      },
      pricing: location.pricing,
      features: formData.features,
      totalCapacity: formData.totalCapacity,
      availableCapacity: formData.availableCapacity,
    }
    editLocationApi.sendRequest(requestParams)
  }
  return (
    <Panel
      header={(
        <PanelHeader
          header="Edit car park "
          editing
          formId="location-edit-form"
          onCancel={onFinish}
          loading={isLoading}
        />
      )}
    >
      <LocationForm
        formId="location-edit-form"
        formValue={formData}
        onChange={setField}
        error={error}
        onSubmit={onFormSubmit}
        mode="edit"
      />

    </Panel>
  )
}

export { LocationEdit }
