import React from 'react'
import { Form, Formik, FormikErrors } from 'formik'
import { Input } from '@rsuite/formik'
import {
  Panel, Grid, Row, Col
} from 'rsuite'

import FieldWithFormik from './formik-fields/FieldWithFormik'
import type { FormProps } from '../types/form'
import { OrganisationGroupVehicles } from '../features/organisations/OrganisationGroupVehicles'
import { useUser } from '../app/hooks'
import { UserRole } from '../types/enums'

type Vehicle = {
  id: string;
  name: string
  email: string
  phone: string
  license: string
  make: string
  model: string
}

interface FormValues {
  id: string;
  name: string;
  vehicles: Vehicle[];
}

type OrganisationGroupFormProps = FormProps & { initialData: any }

function validate (values: FormValues) {
  const errors: FormikErrors<FormValues> = {}

  if (!values.name) {
    errors.name = 'All groups must have a name.'
  }

  if (values.vehicles && values.vehicles.length > 0) {
    values.vehicles.forEach((vehicle) => {
      if (!vehicle.license) {
        errors.vehicles = 'All vehicles must have a license plate.'
      }
      if (!vehicle.email) {
        errors.vehicles = 'All vehicles must have an email.'
      }
      if (!vehicle.phone) {
        errors.vehicles = 'All vehicles must have an phone.'
      }
      if (values.vehicles.filter(
        (v) => v.license.toUpperCase() === vehicle.license.toUpperCase()
      ).length > 1) {
        errors.vehicles = `Duplicate license found: ${vehicle.license}.`
      }
    })
  }

  return errors
}

function OrganisationGroupForm ({
  onSubmit, formId, formValue, initialData, mode,
}: OrganisationGroupFormProps) {
  const user = useUser()
  const isAdmin = user.hasRole(UserRole.ADMIN, UserRole.OPERATOR)
  const initialValues = {
    id: formValue.id,
    name: '',
    vehicles: [],
  }
  if (mode === 'edit') {
    const groups = JSON.parse(initialData.groups)
    const group = groups.find((g: any) => g.id === formValue.id)
    initialValues.name = group?.name || ''
    initialValues.vehicles = group?.vehicles || ''
  }

  return (
    <Formik
      enableReinitialize
      validate={validate}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        const errs = validate(values)
        if (Object.keys(errs).length === 0) {
          setTimeout(() => {
            onSubmit(values)
            actions.setSubmitting(false)
          }, 1000)
        }
      }}
    >
      {
        (form) => (
          <Form
            className="rs-form-group noover"
            autoComplete="off"
            id={formId}
          >
            <Panel header="Group details">
              <Grid>
                <Row>
                  <Col xs={24} sm={12}>
                    <FieldWithFormik
                      required
                      errors={form.errors}
                      touched={form.touched}
                      name="name"
                      label="Enter a unique name:"
                      component={Input}
                      disabled={!isAdmin}
                    />
                  </Col>
                </Row>
                <Row>
                  <FieldWithFormik
                    errors={form.errors}
                    touched={form.touched}
                    name="vehicles"
                    label=""
                    component={OrganisationGroupVehicles}
                  />
                </Row>
              </Grid>
            </Panel>
          </Form>
        )
      }
    </Formik>
  )
}

export { OrganisationGroupForm }
