/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FieldInputProps, FormikProps } from 'formik'
import {
  Button,
  Input,
  Panel, Table
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus } from 'react-icons/fa'
import { v4 as uuid } from 'uuid'

import {
  CenteredLoader,
  PanelHeader
} from '../../components'

import './OrganisationGroupVehicles.css'
import { SearchBar } from '../../components/SearchBar'

import { useApi } from '../../app/hooks'
import { getOrganisation } from '../../services/graphql/queries'

const { Column, HeaderCell, Cell } = Table

interface RowData {
  id: string;
  name?: string
  email?: string
  license?: string
  make?: string
  model?: string
  phone?: string
  colour?: string
  state?: string
}

function InputCell (
  {
    rowData, type, dataKey, updateRow, inputStyle, ...props
  }: { rowData?: RowData, type?: string, dataKey: string, updateRow: any, inputStyle?: any }
) {
  const data = rowData || { id: Date.now().toString() }

  return (
    <Cell id={data.id} style={{ padding: 4 }} {...props}>
      <Input
        style={{ ...inputStyle, marginBottom: 0 }}
        id={data.id}
        value={data[dataKey as keyof RowData]}
        type={type || 'text'}
        onChange={(value) => {
          updateRow({ ...rowData, [dataKey]: value })
        }}
      />
    </Cell>
  )
}

function DeleteCell (
  {
    rowData, deleteRow, ...props
  }: { rowData?: RowData, dataKey: string, deleteRow: any }
) {
  return (
    <Cell {...props}>
      <Button
        style={{ padding: 4, fontWeight: 100 }}
        appearance="link"
        onClick={() => deleteRow(rowData)}
      >
        Delete
      </Button>
    </Cell>
  )
}

/**
 * Group list component
 * @return {ReactElement}
 */
function OrganisationGroupVehicles ({
  field, form,
}: { field: FieldInputProps<'vehicles'>, form: FormikProps<any>}): ReactElement {
  const { organisationId } = useParams()
  const { vehicles: data } = form.values
  const [searchValue, setSearchValue] = React.useState('')
  const [tableData, setTableData] = React.useState(data || [])

  const fetchOrganisationGroupsApi = useApi(getOrganisation)
  const fetchResponse = fetchOrganisationGroupsApi.getResponse()
  const isLoading = fetchResponse.loading

  useEffect(() => {
    if (organisationId) {
      fetchOrganisationGroupsApi.sendRequest({ organisationId })
    }
    return fetchOrganisationGroupsApi.cleanup
  }, [])

  const handleUpdateRow = (rowData: RowData) => {
    const nextData = [...data]
    const itemIndex = nextData.findIndex((item) => item.id === rowData.id)
    if (itemIndex >= 0) nextData[itemIndex] = { ...rowData }
    setTableData(nextData)
    form.setFieldValue(field.name, nextData)
  }

  const handleDeleteRow = (rowData: RowData) => {
    const nextData = [...data].filter((row) => row.id !== rowData.id)
    setTableData(nextData)
    form.setFieldValue(field.name, nextData)
  }

  // TODO: Move this logic to backend with a more efficient algorithm,
  //       as performance will scale terribly as we add more vehicles
  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    setTableData(data.filter((vehicle: RowData) => {
      const vehicleString = `
        ${vehicle.name} ${vehicle.email} ${vehicle.license} ${vehicle.make} ${vehicle.model} ${vehicle.phone}
      `
      return vehicleString.toLowerCase().includes(value.toLowerCase())
    }))
  }
  if (!fetchResponse.data || fetchResponse.loading) {
    return <CenteredLoader />
  }

  return (
    <Panel
      className="organisation-group-vehicles"
      header={(
        <PanelHeader
          loading={isLoading}
          header="Vehicles"
          onEdit={() => {
            const nextData = [
              ...data,
              {
                id: uuid(),
                name: '',
                email: '',
                license: '',
                make: '',
                model: '',
                phone: '',
                colour: '',
                state: '',
              },
            ]
            setTableData(nextData)
            form.setFieldValue(field.name, nextData)
          }}
          icon={<Icon as={FaPlus} />}
        />
      )}
    >
      <SearchBar
        styles={{ marginBottom: 4 }}
        placeholder="Search rows"
        value={searchValue}
        onChange={handleSearchChange}
      />
      <Table
        virtualized
        rowHeight={52}
        height={380}
        bordered
        cellBordered
        data={tableData}
      >
        <Column flexGrow={0.75}>
          <HeaderCell>License Plate</HeaderCell>
          <InputCell
            inputStyle={{ textTransform: 'uppercase' }}
            updateRow={handleUpdateRow}
            dataKey="license"
          />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Make</HeaderCell>
          <InputCell updateRow={handleUpdateRow} dataKey="make" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Model</HeaderCell>
          <InputCell updateRow={handleUpdateRow} dataKey="model" />
        </Column>
        <Column flexGrow={0.5}>
          <HeaderCell>State</HeaderCell>
          <InputCell updateRow={handleUpdateRow} dataKey="state" />
        </Column>
        <Column flexGrow={0.75}>
          <HeaderCell>Colour</HeaderCell>
          <InputCell updateRow={handleUpdateRow} dataKey="colour" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Name</HeaderCell>
          <InputCell updateRow={handleUpdateRow} dataKey="name" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Email</HeaderCell>
          <InputCell updateRow={handleUpdateRow} dataKey="email" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Phone</HeaderCell>
          <InputCell updateRow={handleUpdateRow} dataKey="phone" />
        </Column>
        <Column width={90}>
          <HeaderCell>...</HeaderCell>
          <DeleteCell deleteRow={handleDeleteRow} dataKey="id" />
        </Column>
      </Table>
    </Panel>
  )
}

export {
  OrganisationGroupVehicles
}
