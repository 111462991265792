import React, { ReactElement } from 'react'

import { useSelector, useDispatch } from '../../app/hooks'
import { alertSelectors, alertActions } from '../../store/alerts/slice'
import { Alert } from './Alert'
import { KeyedAlert } from '../../types/alert'

import './Alert.css'

type Props = {
  fixed?: boolean,
}

/**
 * Alert container component
 * @param {Props} props
 * @return {ReactElement}
 */
function AlertContainer (props: Props): ReactElement {
  const { fixed = false } = props
  const dispatch = useDispatch()
  const alerts: Array<KeyedAlert> = useSelector(alertSelectors.getAlerts)

  const remove = (key: string) => {
    dispatch(alertActions.removeAlert(key))
  }
  const classes = ['alerts']
  if (fixed) {
    classes.push('-fixed')
  }

  return (
    <div className={classes.join(' ')}>
      {alerts.map((alert) => (
        <Alert
          key={alert.key}
          type={alert.type}
          title={alert.title}
          message={alert.message}
          trusted={alert.trusted}
          persist={alert.persist}
          onClose={() => remove(alert.key)}
        />
      ))}
    </div>
  )
}

export {
  AlertContainer
}
