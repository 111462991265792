import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Panel } from 'rsuite'

import {
  useAlert, useFormState, usePageTitle, useSelector, useUser, useDispatch
} from '../../app/hooks'
import { VerifyEmailForm } from '../../forms/VerifyEmailForm'
import { startVerifyEmailFlow } from '../../services/user/client'
import { userActions, userSelectors } from '../../store/user/slice'
import { AlertType } from '../../types/enums'
import './VerifyEmail.css'

/**
 * Feature which sends users a verification code via email and a form interface
 * to submit that code
 * @returns {React.ReactElement}
 */
export function VerifyEmail (): React.ReactElement {
  usePageTitle('Verify Email')
  const navigate = useNavigate()
  const user = useUser()
  const pushAlert = useAlert()
  const dispatch = useDispatch()

  const requestCode = () => startVerifyEmailFlow()
  const [formData, setField] = useFormState({ code: '' })

  const status = useSelector(userSelectors.getStatus)

  useEffect(() => {
    if (user.emailVerified) {
      pushAlert({
        type: AlertType.SUCCESS,
        message: 'Your email has been verified',
      })
      navigate('/')
    } else {
      requestCode()
    }
  }, [user.emailVerified])

  const submit = async () => {
    dispatch(userActions.verifyEmail(formData.code))
  }

  return (
    <div className="verify-email">
      <Panel header={<h2> Verify your email </h2>}>
        <div className="instruction">
          A code has been sent to
          {' '}
          {user.email}
          , please enter the code to verify your email.
        </div>
        <VerifyEmailForm
          formValue={formData}
          onChange={setField}
          onSubmit={submit}
          onRequestCode={requestCode}
          loading={status.loading}
          error={status.error}
        />
      </Panel>
    </div>
  )
}
