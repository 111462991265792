import { useEffect, useState } from 'react'

import { getLocations } from '../../services/graphql/queries'
import { ApiResourceHookConfig } from '../../types/api'
import { AlertType } from '../../types/enums'
import { useAlert } from './alert'
import { CustomerLocationHook, Location } from '../../types/location'
import { useApi } from './api'

/**
 * Api resource hook for customer locations
 * @param {ApiResourceHookConfig | undefined} config - The options for this hook
 * @return {CustomerLocationHook}
 */
function useCustomerLocations (config?: ApiResourceHookConfig): CustomerLocationHook {
  const [locations, setLocations] = useState(new Map<string, Location>())

  const locationsRequest = useApi(getLocations)
  const res = locationsRequest.getResponse()
  const pushAlert = useAlert()

  useEffect(() => {
    locationsRequest.sendRequest()
    return locationsRequest.cleanup
  }, [])

  useEffect(() => {
    if (config?.displayErrorAlerts && res.error !== null) {
      pushAlert({
        type: AlertType.ERROR,
        message: res.error.message,
        key: res.error.requestId,
      })
    }
  }, [res.error])

  useEffect(
    () => {
      if (res.data !== null) {
        const map = new Map<string, Location>()
        res.data.items.forEach((e) => map.set(e.id, e))
        setLocations(map)
      }
    },
    [res.data]
  )

  return {
    locations: {
      ...res,
      data: locations,
    },
  }
}

export {
  useCustomerLocations
}
