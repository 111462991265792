import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Table, Tag } from 'rsuite'

import { useUser } from '../../app/hooks'
import { ActionMenu, PaginationControls, TableData } from '../../components'
import { getSubscriptionStatusTagColor } from './helpers'
import { isSubscriptionStatus } from '../../types/subscriptionHelpers'

import { Subscription } from '../../types/subscription'
import { RowData } from '../../types/table'
import './AdminSubscriptionsTable.css'
import { UserRole, SubscriptionStatus } from '../../types/enums'
import { SearchBar } from '../../components/SearchBar'

type Props = {
  subscriptions: Subscription[]
  // eslint-disable-next-line no-unused-vars
  cancelCb?: (subscription: Subscription) => void
  loading?: boolean
  // eslint-disable-next-line no-unused-vars
  reviewCb?: (subscription: Subscription) => void
  // eslint-disable-next-line no-unused-vars
  editCb: (subscription: Subscription) => void
  nextPage?: () => void
  prevPage?: () => void
}

/**
 * Table listing subscriptions for admins to see
 * @param {Props} props
 * @param {Subscription[]} props.subscriptions                - The subscriptions to list
 * @param {boolean} props.loading                             - Whether table data is loading
 * @param {()=>{}} props.nextPage                             - Callback when next button is clicked
 * @param {()=>{}} props.prevPage                             - Callback when prev button is clicked
 * @param {(subscription:Subscription) => void} props.cancelCb  - Cb to cancel a subscription
 * @param {(subscription:Subscription)=>void} props.reviewCb  - if defined, a review button will be
 *                                                              added to each row, this cb will be called
 *                                                              when button is clicked
 * @return {React.ReactElement}
 */
function AdminModifiedSubscriptionTable (props:Props):React.ReactElement {
  const {
    nextPage, prevPage, loading, subscriptions, reviewCb,
    cancelCb, editCb,
  } = props
  const user = useUser()
  const [searchValue, setSearchValue] = React.useState('')
  const [tableData, setTableData] = React.useState(subscriptions)

  useEffect(() => {
    setTableData(subscriptions)
  }, [loading, subscriptions])

  /**
   * Table.Cell child for rendering a subscriptions's name
   * @param {Subscription} subscription
   * @return {ReactElement}
   */
  const renderPlan = (subscription: RowData): React.ReactElement => (
    <div>
      {subscription.plan.name}
    </div>
  )

  /**
   * Table.Cell child for rendering a subscriptions's username
   * @param {Subscription} subscription
   * @return {ReactElement}
   */
  const renderUser = (subscription: RowData):React.ReactNode => {
    if (!subscription || !subscription.user) {
      return '-'
    }
    const name = `${subscription.user.givenName} ${subscription.user.familyName}`
    if (!user.hasRole(UserRole.ADMIN)) {
      return name
    }
    return (
      <Link to={`/${user.getRoleForRoute()}/users/${subscription.user.id}`}>{name}</Link>
    )
  }

  /**
   * Table.Cell child for rendering a subscriptions's carpark
   * @param {Subscription} subscription
   * @return {ReactElement}
   */
  const renderCarpark = (subscription: RowData):React.ReactElement => (
    <div>
      {subscription?.location?.name}
    </div>
  )

  /**
   * Table.Cell child for rendering a subscription's carpark
   * @param {Subscription} subscription
   * @return {ReactElement}
   */
  const renderStatus = (subscription: RowData):React.ReactElement => {
    const text = subscription.status === SubscriptionStatus.AMEND
      ? 'AMENDMENT PENDING' : subscription.status
    if (isSubscriptionStatus(subscription?.status)) {
      const color = getSubscriptionStatusTagColor(subscription.status)
      return (
        <Tag color={color}>
          {text}
        </Tag>
      )
    }
    return (
      <Tag>
        {text}
      </Tag>
    )
  }

  /**
   * Table.Cell child for rendering an optional button for reviewing the subscription
   * @param {Subscription} subscription
   * @return {ReactElement}
   */
  const renderReviewButton = (subscription:RowData):React.ReactElement => (
    <div>
      <Button onClick={() => reviewCb?.(subscription as any)}>Review</Button>
    </div>
  )

  /**
   *  Table.Cell child for rendering subscription actions
   *  @param {Subscription} subscription
   *  @return {ReactElement}
   */
  const renderActions = (subscription:RowData): React.ReactElement => {
    const actions = []
    if (
      subscription?.status !== SubscriptionStatus.CANCELLED
      && subscription?.status !== SubscriptionStatus.AMEND
    ) {
      actions.push({
        label: 'Edit subscription',
        action: () => editCb(subscription as any),
      })
    }
    if (cancelCb && [SubscriptionStatus.ACTIVE, SubscriptionStatus.UNPAID].includes(subscription?.status)) {
      actions.push({
        label: 'Cancel subscription',
        action: () => cancelCb(subscription as any),
      })
    }
    return <ActionMenu actions={actions} />
  }

  // TODO: Move this logic to backend with a more efficient algorithm,
  //       as performance will scale terribly as we add more vehicles
  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    setTableData(subscriptions.filter((subscription: RowData) => {
      const subscriptionString = `
        ${subscription.user.givenName} ${subscription.user.familyName}
        ${subscription.location.name} ${subscription.plan.name}
        ${subscription.vehicles} ${subscription.status}
        ${new Date(subscription.endDate).toLocaleDateString() ?? ''}
        ${new Date(subscription.requestDate).toLocaleDateString() ?? ''}
      `
      return subscriptionString.toLowerCase().includes(value.toLowerCase())
    }))
  }

  return (
    <div className="admin-subscription-table">
      <SearchBar
        styles={{ marginBottom: 4 }}
        placeholder="Search rows"
        value={searchValue}
        onChange={handleSearchChange}
      />
      <Table
        wordWrap="break-word"
        data={tableData}
        loading={loading}
        autoHeight
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>User</Table.HeaderCell>
          <TableData dataKey="username" content={renderUser} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Car park</Table.HeaderCell>
          <TableData dataKey="carpark" content={renderCarpark} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Plan name</Table.HeaderCell>
          <TableData dataKey="planName" content={renderPlan} />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Current bays</Table.HeaderCell>
          <TableData dataKey="currentVehicles" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Requested bays</Table.HeaderCell>
          <TableData dataKey="requestedVehicles" />
        </Table.Column>
        <Table.Column flexGrow={1.5}>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <TableData dataKey="status" content={renderStatus} />
        </Table.Column>
        { reviewCb
          ? (
            <>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Request Date</Table.HeaderCell>
                <TableData dataKey="requestDate" format="date" />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Review</Table.HeaderCell>
                <TableData dataKey="review" content={renderReviewButton} />
              </Table.Column>
            </>
          )
          : (
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>End Date</Table.HeaderCell>
              <TableData dataKey="endDate" format="date" />
            </Table.Column>
          )}
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Actions</Table.HeaderCell>
          <TableData dataKey="actions" content={renderActions} />
        </Table.Column>
      </Table>
      <div className="pagination">
        <PaginationControls nextPage={nextPage} prevPage={prevPage} />
      </div>
    </div>
  )
}

export {
  AdminModifiedSubscriptionTable
}
