import React, { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from 'rsuite'

import {
  useApi
} from '../../app/hooks'
import { getLocation } from '../../services/graphql/queries'
import { LocationDefinition } from './LocationDefinition'
import { LocationEdit } from './LocationEdit'

/**
 * View/edit location
 * @return {ReactElement}
 */
function LocationDetails (): ReactElement {
  const [editing, setEditing] = useState(false)
  const { locationId } = useParams()
  const fetchApi = useApi(getLocation)
  const fetchRes = fetchApi.getResponse()

  useEffect(() => {
    if (locationId) {
      fetchApi.sendRequest({ locationId })
    }
    return () => {
      fetchApi.cleanup()
    }
  }, [])

  const onEdit = () => {
    setEditing(true)
  }

  const onEditFinish = () => {
    setEditing(false)
    if (locationId) {
      fetchApi.sendRequest({ locationId })
    }
  }

  return (
    <>
      {(fetchRes.data === null || fetchRes.loading) && <Loader center />}
      { !editing && fetchRes.data && <LocationDefinition onEdit={onEdit} location={fetchRes.data} /> }
      { editing && fetchRes.data && <LocationEdit location={fetchRes.data} onFinish={onEditFinish} />}
    </>
  )
}

export {
  LocationDetails
}
