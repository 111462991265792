import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import TagManager from 'react-gtm-module'

import { store } from './app/store'
import { Router } from './router'
import * as serviceWorker from './serviceWorker'

import 'rsuite/dist/rsuite.min.css'
import './index.css'
import './theme.css'
import './font.css'
import './print.css'

const gtmId = process.env.REACT_APP_GTM_ID
if (gtmId && gtmId.match(/GTM-[A-Z0-9]{6}/)) {
  TagManager.initialize({ gtmId })
} else {
  // eslint-disable-next-line no-console
  console.warn('Ignoring missing or invalid GTM ID')
}

export function App () {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <Router />
      </Provider>
    </React.StrictMode>
  )
}

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(<App />)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
