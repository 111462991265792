import { TypeAttributes } from 'rsuite/esm/@types/common'

import { GateStatus } from '../../types/enums'

/**
 *  Returns a tag color which matches a subscription status.
 *  For some reason, for a RSuite tag to display its default colour, I have to return `undefined`
 *  This makes me annoyed.
 *  @param {GateStatus} status
 *  @return {TypeAttributes.Color|undefined}
 */
function getGateStatusTagColor (status:GateStatus): TypeAttributes.Color | undefined {
  switch (status) {
    case GateStatus.ACTIVE:
      return 'green'
    case GateStatus.OFFLINE:
      return 'red'
    default:
      return undefined
  }
}

export {
  getGateStatusTagColor
}
