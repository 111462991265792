import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import {
  Panel, Table, Tag, TagGroup
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus } from 'react-icons/fa'

import { useApi, useModal } from '../../app/hooks'
import { getLocationGates, getLocation, removeGate } from '../../services/graphql/queries'
import {
  ConfirmModal, TableData, ActionMenu, Modal, PanelHeader
} from '../../components'
import { isGateStatus } from '../../types/gateHelpers'
import { CameraGroup } from '../../types/location'
import { getGateStatusTagColor } from './helpers'
import { deleteFromPaginatedWithId } from '../../helpers'
import type { RowData } from '../../types/table'
import type { GateConfig } from '../../types/gate'

const { Column, HeaderCell, Cell } = Table

/**
 * Organisation list component
 * @return {ReactElement}
 */
function LocationGateList (): ReactElement {
  const navigate = useNavigate()

  const { locationId } = useParams()
  const gateQuery = useApi(getLocationGates)
  const locationQuery = useApi(getLocation)
  const deleteQuery = useApi(removeGate)

  const gateData = gateQuery.getResponse()
  const locationData = locationQuery.getResponse()
  const modal = useModal()
  const [gateForRemoval, setGateForRemoval] = useState<GateConfig>()

  useEffect(() => {
    if (locationId) {
      gateQuery.sendRequest({ locationId })
      locationQuery.sendRequest({ locationId })
    }
    return () => {
      gateQuery.cleanup()
      locationQuery.cleanup()
      deleteQuery.cleanup()
    }
  }, [])

  /**
   * Show the gate removal confirmation modal
   * @param {RowData} gate - The gate to remove
   */
  const confirmRemoval = (gate: RowData) => {
    setGateForRemoval(gate as GateConfig)
    modal.show()
  }
  /**
   * Remove the gate from the location and reload the gate list
   */
  const remove = () => {
    if (gateForRemoval) {
      deleteQuery.sendRequest({
        gateId: gateForRemoval.id,
      }, () => {
        gateQuery.setData((oldData: any) => deleteFromPaginatedWithId(oldData, gateForRemoval.id))
      })
    }
  }

  /**
   * Dropdown menu for a row in the gates list table
   * @param {RowData} gate - The gate object of the row
   * @return {ReactElement}
   */
  const renderActions = (gate: RowData): ReactElement => {
    const actions = [
      { label: 'Delete', action: () => confirmRemoval(gate) },
    ]
    return (
      <ActionMenu actions={actions} />
    )
  }

  /**
  * Table.Cell child for rendering a gate's status
  * @param {RowData} gate
  * @return {ReactElement}
  */
  const renderStatus = (gate: RowData): React.ReactElement => {
    if (isGateStatus(gate?.status)) {
      const color = getGateStatusTagColor(gate.status)
      return (
        <Tag color={color}>
          {gate.status}
        </Tag>
      )
    }
    return (
      <Tag>
        {gate.status}
      </Tag>
    )
  }

  /**
   * Render a status cell for a single gate
   * @param {RowData} gate
   * @return {ReactElement}
   */
  const renderType = (gate: RowData): ReactElement => (
    <TagGroup>
      <Tag>{gate.type}</Tag>
    </TagGroup>
  )

  const gates = (locationData.data && gateData.data?.items) || []

  /**
   * Get a string listing the camera groups a given gate is a member of
   * @param {RowData} gate - The gate data
   * @return {string}
   */
  const formatCameraGroups = (gate: RowData): string => {
    const cameraGroups = locationData.data?.cameraGroups || null
    return cameraGroups?.filter((group: CameraGroup) => group.gates.includes(gate.id))
      .map((group: CameraGroup) => group.name)
      .join(', ') || ''
  }

  /**
   * Get the controller version from the gate details
   * @param {RowData} gate
   * @return {string}
   */
  const formatVersion = (gate: RowData): string => {
    const versions = gate.controllerStatus?.versions
    return versions
      ? versions
        .split(';')
        .find((s: string) => s.includes('controller'))
        .replace(/.+=/, 'v')
      : ''
  }

  return (
    <>
      <Panel
        className="gates-list location-panel"
        header={(
          <PanelHeader
            header="Gates"
            onEdit={() => navigate('gates/new')}
            icon={<Icon as={FaPlus} />}
          />
        )}
      >
        <Table
          autoHeight
          data={gates}
          loading={gateData.loading}
        >
          <Column flexGrow={1}>
            <HeaderCell>Gate name</HeaderCell>
            <Cell dataKey="name">
              {(rowData) => (
                <Link to={`gates/${rowData.id}`}>
                  {rowData.name}
                </Link>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Lane ID</HeaderCell>
            <Cell dataKey="lane" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Type</HeaderCell>
            <TableData dataKey="type" content={renderType} />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Controller version</HeaderCell>
            <Cell dataKey="versions">
              {(rowData) => (
                formatVersion(rowData)
              )}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Camera groups</HeaderCell>
            <Cell dataKey="cameraGroups">
              {(rowData) => (
                formatCameraGroups(rowData)
              )}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Status</HeaderCell>
            <TableData dataKey="status" content={renderStatus} />
          </Column>
          <Column flexGrow={0.5}>
            <HeaderCell>Actions</HeaderCell>
            <TableData dataKey="action" content={renderActions} />
          </Column>
        </Table>
      </Panel>
      <Modal hook={modal}>
        <ConfirmModal
          message={`The ${gateForRemoval?.name} gate will be removed from this location.`}
          onConfirm={remove}
          onClose={modal.hide}
        />
      </Modal>
    </>
  )
}

export {
  LocationGateList
}
