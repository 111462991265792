import React from 'react'

import { CtaButton } from '../../components'
import './CustomerDashboardBanner.css'
import { ProfileStep } from '../../types/enums'
import { CustomerDashboardProfileInfo } from '../../types/dashboard'

const description: Record<ProfileStep, string> = {
  payment: 'To activate Noover, a payment method most be added to your profile.'
  + " It's easy to do, and can be added in My Account",
  subscription: 'To activate Noover, you must make a subscription to a carpark',
  vehicle: 'For us to let your vehicles through, you must register them with Noover',
  done: 'You are ready to park at a carpark under your subscription and we\' take care of the rest',
}

const ctaLabel: Record<ProfileStep, string> = {
  payment: 'Add payment method',
  subscription: 'Add a subscription',
  vehicle: 'Add a vehicle',
  done: 'Hmmm',
}

const ctaDescription: Record<ProfileStep, string> = {
  payment: 'Add payment method to subscribe',
  subscription: 'Add a subscription to park',
  vehicle: 'Add a subscription to park',
  done: 'hmmmm',
}

/** Prop type for {@link CustomerDashboardBanner} */
type CustomerDashboardBannerProps = CustomerDashboardProfileInfo

/**
 *  Banner displayed on the customer dashboard
 */
function CustomerDashboardBanner (props: CustomerDashboardBannerProps): React.ReactElement {
  const {
    vehiclesActive,
    subscriptionsActive, paymentMethodExists, addVehicleCb, addSubscriptionCb,
    addPaymentMethodCb,
    vehicleIcon,
    subscriptionIcon,
    paymentIcon,
  } = props

  const action: Record<ProfileStep, ()=>void> = {
    payment: addPaymentMethodCb,
    subscription: addSubscriptionCb,
    vehicle: addVehicleCb,
    done: () => {},
  }

  const ctaIcon: Record<ProfileStep, React.ReactElement> = {
    payment: paymentIcon,
    subscription: subscriptionIcon,
    vehicle: vehicleIcon,
    done: vehicleIcon,
  }

  let completedSteps = 3
  let step = ProfileStep.DONE
  if (!vehiclesActive) {
    completedSteps -= 1
    step = ProfileStep.VEHICLE
  }
  if (!subscriptionsActive) {
    completedSteps -= 1
    step = ProfileStep.SUBSCRIPTION
  }
  if (!paymentMethodExists) {
    completedSteps -= 1
    step = ProfileStep.PAYMENT
  }
  return (
    <div className="dashboard-banner">
      <div className="left">
        <div className="banner-description">
          <div className="title">
            {
              step === ProfileStep.DONE
                ? 'You are all set to get Nooving!'
                : `Your account is ${completedSteps}/3 complete`
            }
          </div>
          <div className="description">
            {description[step]}
          </div>
        </div>
      </div>
      { step !== ProfileStep.DONE
      && (
        <div className="right">
          <div className="banner-cta">
            <div className="description">
              {ctaDescription[step]}
            </div>
            <div className="cta">
              <CtaButton variant="lg" label={ctaLabel[step]} onClick={action[step]} icon={ctaIcon[step]} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export {
  CustomerDashboardBanner,
  type CustomerDashboardBannerProps
}
