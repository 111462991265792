import { useDispatch } from './redux'
import { alertActions } from '../../store/alerts/slice'
import type { Alert } from '../../types/alert'

/**
 * A hook for dispatching an alert message
 * @return {function} - A callback to use to dispatch an alert to the Redux store
 */
const useAlert = () => {
  const dispatch = useDispatch()
  return (alert: Alert) => {
    dispatch(alertActions.pushAlert(alert))
  }
}

export {
  useAlert
}
