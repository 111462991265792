import React, { ReactElement, useEffect } from 'react'

import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { Panel, Loader } from 'rsuite'

import {
  useApi, useApiGroup, useFormState, usePageTitle, useUser
} from '../../app/hooks'
import { getGateConfig, getLocation } from '../../services/graphql/queries'
import { LocationGateForm } from '../../forms/LocationGateForm'
import { PanelHeader } from '../../components'
import { createGate, updateGate } from '../../services/graphql/queries/gates'
import { CreateGateParams } from '../../types/gate'
import { NotFound } from '../error-pages/NotFound'

function findGateCameraGroupInLocationData (
  gateId: string | undefined,
  locationCameraGroups: Array<any>
): string {
  if (!gateId) return ''
  let groupName = ''

  locationCameraGroups.forEach((cameraGroup: any) => {
    const group = cameraGroup.gates.find((gate: string) => gate === gateId)
    if (group) groupName = cameraGroup.name
  })

  return groupName
}

/**
 * Component to create Gate
 * @returns {ReactElement}
 */
function LocationGateDetails () : ReactElement {
  const user = useUser()
  const navigate = useNavigate()
  const { locationId, gateId } = useParams()
  const api = useApiGroup({
    fetch: useApi(getGateConfig),
    create: useApi(createGate),
    update: useApi(updateGate),
    fetchLocation: useApi(getLocation),
  })

  const fetchResponse = api.requests.fetch.getResponse()
  const fetchLocationResponse = api.requests.fetchLocation.getResponse()
  const saveResponse = gateId
    ? api.requests.update.getResponse()
    : api.requests.create.getResponse()

  useEffect(() => {
    api.requests.fetchLocation.sendRequest({ locationId })
    if (gateId) {
      api.requests.fetch.sendRequest({ gateId })
    }
    return api.cleanup
  }, [])

  const gateCameraGroup = findGateCameraGroupInLocationData(
    gateId,
    fetchLocationResponse?.data?.cameraGroups ?? []
  )
  const initialValues = gateId ? fetchResponse.data : { name: '' }

  const [isLoading, setIsLoading] = React.useState(true)
  const [formData, setField, resetForm] = useFormState(initialValues)

  useEffect(() => {
    if (!api.requests.fetch.getResponse().loading && !api.requests.fetchLocation.getResponse().loading) {
      setIsLoading(false)
      resetForm(gateId ? { ...fetchResponse.data, cameraGroup: gateCameraGroup } : { name: '' })
    }
  }, [api.requests.fetch.getResponse().loading, api.requests.fetchLocation.getResponse().loading])

  const pageTitle = gateId ? 'Edit gate' : 'New gate'
  usePageTitle(pageTitle)

  if (fetchResponse.success && !fetchResponse.data) {
    return <NotFound />
  }

  if (saveResponse.success) {
    return <Navigate to={`/${user.getRoleForRoute()}/locations/${locationId}`} />
  }

  /**
   * Create or update a gate using the API
   */
  const save = () => {
    const gate = { ...formData, location: locationId } as CreateGateParams
    if (gateId) {
      api.requests.update.sendRequest({ gateId, ...gate })
    } else {
      api.requests.create.sendRequest(gate)
    }
  }

  /**
   * Return to the location list screen
   */
  const cancel = () => {
    navigate(`/${user.getRoleForRoute()}/locations/${locationId}`)
  }

  const formId = 'gate-details-form'
  return (
    <Panel
      header={(
        <PanelHeader
          loading={saveResponse.loading}
          editing
          formId={formId}
          onCancel={cancel}
          header={pageTitle}
        />
      )}
    >
      {isLoading
        ? <Loader center content="Loading..." />
        : (
          <LocationGateForm
            formId={formId}
            formValue={formData}
            onChange={setField}
            onSubmit={save}
            error={saveResponse.error}
            mode={gateId ? 'edit' : 'new'}
          />
        )}
    </Panel>
  )
}

export {
  LocationGateDetails
}
