/* eslint-disable no-shadow */

enum ProfileStep {
  PAYMENT = 'payment',
  SUBSCRIPTION = 'subscription',
  VEHICLE = 'vehicle',
  DONE= 'done'
}

/** Result of threeds card verification */
enum CardVerificationResult {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

/** Admin actions for reviewing subscriptions */
enum ReviewSubscriptionAction {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT'
}

/** Status of subscriptions */
enum SubscriptionStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  UNPAID = 'UNPAID',
  AMEND = 'AMEND'
}

/** Types which implement the pricing type */
enum PricingType {
  PLAN = 'PLAN'
}

/** Basic user roles */
enum UserRole {
  ADMIN = 'Admins',
  CUSTOMER = 'Customers',
  ORGANISATION = 'Organisation',
  OPERATOR = 'Operators',
  ATTENDANT = 'Attendants',
}

/** Redux store statuses */
enum StoreStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  FAILED = 'failed',
}

/** User login statuses */
enum LoginStatus {
  READY = 'ready',
  AUTHENTICATED = 'authenticated',
  UNCONFIRMED = 'unconfirmed',
  PASSWORD_RESET = 'password-reset',
}

/** Errors from the user system */
enum UserErrorCode {
  USERNAME_EXISTS = 'UsernameExistsException',
  INVALID_PASSWORD = 'InvalidPasswordException',
  BAD_USERNAME = 'UserNotFoundException',
  BAD_PASSWORD = 'NotAuthorizedException',
  BAD_CODE = 'CodeMismatchException',
  EXPIRED_CODE = 'ExpiredCodeException',
}

/** Errors from the GraphQL API */
enum ApiErrorCode {
  GENERIC_GRAPHQL_ERROR = 'GenericGraphqlError',
}

/** Alert style types */
enum AlertType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

/** Vehicle statuses */
enum VehicleStatus {
  ACTIVE = 'ACTIVE',
  NOT_REGISTERED = 'NOT_REGISTERED',
  DEREGISTERED = 'DEREGISTERED',
}

/** Account and vehicle blocking flags */
enum Flag {
  ADMIN_BLOCKED = 'ADMIN_BLOCKED',
  NO_PAYMENT_METHOD = 'NO_PAYMENT_METHOD',
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  PAYMENT_DECLINED_FIRST_ATTEMPT = 'PAYMENT_DECLINED_FIRST_ATTEMPT',
  PAYMENT_DECLINED_SECOND_ATTEMPT = 'PAYMENT_DECLINED_SECOND_ATTEMPT',
  PAYMENT_DECLINED_MULTIPLE_ATTEMPTS = 'PAYMENT_DECLINED_MULTIPLE_ATTEMPTS',
}

/** Australian states and territories */
enum State {
  ACT = 'ACT',
  NSW = 'NSW',
  NT = 'NT',
  QLD = 'QLD',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA',
}

/** Organisation statuses */
enum OrganisationStatus {
  NO_PAYMENT_CREDENTIALS = 'NO_PAYMENT_CREDENTIALS',
  OK = 'OK',
}

/** Location statuses */
enum LocationStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  TESTING = 'TESTING',
  DISABLED = 'DISABLED',
}

/** Tariff statuses */
enum TariffStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

/** Location types */
enum LocationType {
  CARPARK = 'CARPARK',
}

/** Location Features */
enum LocationFeature {
  PARKING_SUBSCRIPTION = 'PARKING_SUBSCRIPTION',
  PARKING_CASUAL = 'PARKING_CASUAL',
  PARKING_ACCESS_LIST = 'PARKING_ACCESS_LIST',
  PARKING_ORGANISATION = 'PARKING_ORGANISATION',
}

/** Gate Types */
enum GateType {
  ENTRY = 'ENTRY',
  EXIT = 'EXIT',
  ACCESS = 'ACCESS',
}

enum GateStatus {
  ACTIVE = 'ACTIVE',
  OFFLINE= 'OFFLINE',
}

/** Sort order to return data in */
enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

/** Controller Status */
// enum ControllerStatus {
//   OK = 'OK',
//   NO_CONFIG = 'NO_CONFIG',
// }

/** Payment statuses */
enum PaymentStatus {
  NO_PAYMENT_METHOD = 'NO_PAYMENT_METHOD',
  PAYMENT_ACCEPTED = 'PAYMENT_ACCEPTED',
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  PAYMENT_GATEWAY_ERROR = 'PAYMENT_GATEWAY_ERROR',
  PAYMENT_NOT_REQUIRED = 'PAYMENT_NOT_REQUIRED',
  PAYMENT_NOT_PROCESSED = 'PAYMENT_NOT_PROCESSED',
}

/** Access list types */
enum AccessListType {
  ALLOW = 'ALLOW',
  DENY = 'DENY',
}

enum VehicleAccess {
  ALLOWED = 'ALLOWED',
  DENIED = 'DENIED',
  FLAGGED = 'FLAGGED',
  SUBSCRIPTION = 'SUBSCRIPTION',
  ORGANISATION = 'ORGANISATION',
  CASUAL = 'CASUAL',
  NO_ACCESS = 'NO_ACCESS',
}

enum EventStatus {
  CREATED_ONLINE = 'CREATED_ONLINE',
  CREATED_OFFLINE = 'CREATED_OFFLINE',
  CREATED_MANUALLY = 'CREATED_MANUALLY',
  MISSING_ENTRY = 'MISSING_ENTRY',
}

export {
  EventStatus,
  VehicleAccess,
  SortOrder,
  UserRole,
  StoreStatus,
  LoginStatus,
  UserErrorCode,
  ApiErrorCode,
  AlertType,
  VehicleStatus,
  Flag,
  State,
  OrganisationStatus,
  LocationStatus,
  LocationType,
  PricingType,
  ReviewSubscriptionAction,
  ProfileStep,
  SubscriptionStatus,
  LocationFeature,
  GateType,
  CardVerificationResult,
  GateStatus,
  PaymentStatus,
  AccessListType,
  TariffStatus
}
