import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAlert, useUser } from '../../app/hooks'
import { AlertType } from '../../types/enums'

/**
 * Banner alert to remind users that their email has not been verified.
 * @return {ReactElement}
 */
export function EmailVerficationReminder (): null {
  const user = useUser()
  const location = useLocation()

  const pushAlert = useAlert()

  useEffect(() => {
    if (
      !location.pathname.startsWith('/account/verify-email')
      && !user.emailVerified
    ) {
      const message = 'Verify your email to complete your account. '
      + '<a href="/account/verify-email">Click here to verify</a>.'

      pushAlert({
        type: AlertType.WARNING,
        title: 'Email not verified',
        message,
        trusted: true,
        persist: true,
      })
    }
  }, [user.emailVerified])

  return null
}
