import React, { useEffect, useState } from 'react'

import { AsyncThunk } from '@reduxjs/toolkit'

import { nanoid } from 'nanoid'

import {
  useToaster, Notification
} from 'rsuite'

import { useDispatch, useSelector } from './redux'
import { apiActions, apiSelectors } from '../../store/api-data/slice'
import type {
  Query, QueryOptions, StatusedApiResponse, ApiHook, ApiGroupHook,
  ThunkArgs,
  ApiSetter,
  RecievedTransformer
} from '../../types/api'

/**
 * A hook for managing dynamic request and response data in the API store
 * @param {Query} query - A GraphQL query function
 * @param {QueryOptions} passedOptions - Query options (see type definition for details)
 * @return {ApiHook} - An object containing methods for dispatching a request and selecting its response
 */
const useApi = <Type, Params = void>(
  query: Query<Type, Params>,
  passedOptions: QueryOptions<Type, Params> = {}
): ApiHook<Type, Params> => {
  const dispatch = useDispatch()
  const [key, setKey] = useState<any>(undefined)
  const [id] = useState(passedOptions.id || nanoid())

  // Declare correct typing for thunk and api reducer
  const apiRequest: AsyncThunk<void, ThunkArgs<Type>, {}> = apiActions.request

  const [options] = useState(passedOptions)
  const [params, setParams] = useState<Params>()
  // const pushAlert = useAlert()
  const toaster = useToaster()

  const response: StatusedApiResponse<Type> = (
    useSelector(apiSelectors.getResponse(id))
  )

  /**
   * Dispatch the API request via Redux
   * @param {Params} - All parameters for the supplied query
   */
  const sendRequest = (passedParams: Params, onReceived?: RecievedTransformer<Type>): void => {
    setParams(passedParams)
    dispatch(apiRequest({ id, query: query(passedParams), transformer: onReceived }))
  }

  /**
   * Get the request parameters
   * @return {Params}
   */
  const getParams = () => params || null

  /**
   * Get the response data
   * @return {StatusedApiResponse}
   */
  const getResponse = (): StatusedApiResponse<Type> => response

  /**
   * Reset an API request to the initial values
   */
  const reset = (): void => {
    dispatch(apiActions.reset(id))
  }

  const setData = (setter: ApiSetter<Type>) => {
    const data = getResponse()?.data || null
    const newData = setter(data)
    dispatch(apiActions.setData({ id, data: newData }))
  }

  /**
   * Remove the request and response data from the API state
   */
  const cleanup = () => {
    dispatch(apiActions.clear(id))
  }

  useEffect(() => {
    if (options.initialFetch) {
      sendRequest(options.initialFetchParams, options.initialOnRecieved)
    }
    return () => {
      if (options.cleanUpOnDismount) {
        cleanup()
      }
    }
  }, [])

  useEffect(() => {
    toaster.remove(key)
    if (response.error) {
      const message = (
        <Notification type="error" header="Error" closable>
          <p>
            <b>{response.error.message}</b>
          </p>
          <p>Please try again or contact support.</p>
        </Notification>
      )
      const newKey = toaster.push(message, { placement: 'bottomStart' })
      setKey(newKey)
    }
  }, [response.error])

  return {
    id,
    sendRequest,
    getResponse,
    getParams,
    reset,
    cleanup,
    setData,
  }
}

/**
 * A hook for managing the state of multiple API requests
 * @param {Record<string, ApiHook>} hooks - An object containing multiple API hooks. Object keys are used
 *                                          for fetching response data from the resulting API group
 * @return {ApiGroupHook} - An object containing all child hooks, with a single cleanup method
 */
const useApiGroup = (hooks: Record<string, ApiHook<any, any>>): ApiGroupHook => ({
  requests: hooks,
  cleanup: () => Object.values(hooks).forEach(({ cleanup }) => cleanup()),
})

export {
  useApi,
  useApiGroup
}
