import { createSlice } from '@reduxjs/toolkit'

import * as reducers from './reducers'
import * as selectors from './selectors'
import type { KeyedAlert } from '../../types/alert'

export type AlertState = {
  alerts: Array<KeyedAlert>
  previousKeys: Array<string>,
}

const initialState: AlertState = {
  alerts: [],
  previousKeys: [],
}

const slice = createSlice({
  name: 'alerts',
  initialState,
  reducers,
})

const alertActions = slice.actions
const alertReducer = slice.reducer
const alertSelectors = selectors

export {
  alertActions,
  alertReducer,
  alertSelectors
}
