import React, { ReactElement } from 'react'

import { Panel } from 'rsuite'

import { usePageTitle } from '../../app/hooks'
import { Carpark, Location } from '../../types/location'
import { DefinitionList, PanelHeader } from '../../components'
import { LocationPricing } from './LocationPricing'
import { LocationGateList } from '../gates/LocationGateList'
import { LocationTariffList } from '../tariffs/LocationTariffList'
import { AccessListList } from '../access-lists/AccessListList'
import { locationIsCarpark } from '../../types/locationHelpers'
import { LocationGroups } from '../groups/LocationGroups'

type Props = {
  location : Location
  onEdit : () => void
}

/**
 * Component to display information about a single location
 * @param {Props} props
 * @param {Location} props.location - Location object
 * @param {()=>void} props.onEdit
 * @returns {ReactElement}
 */
function LocationDefinition (props: Props): ReactElement {
  const { location, onEdit } = props
  const locationData = {
    Name: location.name,
    Description: location.description,
    Address: location.address,
    'Time zone': location.timezone,
  }

  /**
   * Returns the definitions list object for a carpark
   * @param {Carpark} carpark - The carpark to detail
   * @return {Object}         - The definition list object
   */
  const getCarparkDefinition = (carpark:Carpark) => {
    const { total, available, verified } = carpark.capacity
    const percentage = total === 0 ? 1 : 1 - available / total
    const formatter = new Intl.NumberFormat('en', { style: 'percent' })
    return ({
      'Total spaces': total,
      Available: available,
      Occupancy: formatter.format(percentage),
      'Last verified': verified,
    })
  }

  const occupancyData = locationIsCarpark(location) ? getCarparkDefinition(location) : {}

  usePageTitle('Car park details')
  return (
    <div>
      <Panel
        header={(
          <PanelHeader
            header="Car park details"
            onEdit={onEdit}
          />
        )}
      >
        <DefinitionList data={locationData} />
      </Panel>
      <Panel
        className="location-panel"
        header={(
          <h2>Occupancy</h2>
        )}
      >
        <DefinitionList data={occupancyData} />
      </Panel>
      <LocationPricing pricings={location.pricing} />
      <LocationTariffList />
      <LocationGroups />
      <LocationGateList />
      <AccessListList location={location} />
    </div>
  )
}

export { LocationDefinition }
