import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'

import { RequireRole } from '../../features/access/RequireRole'
import { LocationList } from '../../features/locations/LocationList'
import { LocationDetails } from '../../features/locations/LocationDetails'
import { UserRole } from '../../types/enums'
import { LocationCreate } from '../../features/locations/LocationCreate'
import { LocationGateDetails } from '../../features/gates/LocationGateDetails'
import { LocationTariffDetails } from '../../features/tariffs/LocationTariffDetails'
import { AccessListDetails } from '../../features/access-lists/AccessListDetails'
import { NotFound } from '../../features/error-pages/NotFound'
import { LocationGroupDetails } from '../../features/groups/LocationGroupDetails'
import { LocationTariffTesting } from '../../features/tariffs/LocationTariffTesting'

/**
 * LocationsPage route
 * @return {ReactElement}
 */
function LocationsPage (): ReactElement {
  return (
    <RequireRole role={UserRole.OPERATOR}>
      <Routes>
        <Route path="/" element={<LocationList />} />
        <Route path="/new" element={<LocationCreate />} />
        <Route path="/:locationId" element={<LocationDetails />} />
        <Route path="/:locationId/gates/new" element={<LocationGateDetails />} />
        <Route path="/:locationId/gates/:gateId" element={<LocationGateDetails />} />
        <Route path="/:locationId/access-lists/new" element={<AccessListDetails />} />
        <Route path="/:locationId/access-lists/:accessListId" element={<AccessListDetails />} />
        <Route path="/:locationId/tariffs/new" element={<LocationTariffDetails />} />
        <Route path="/:locationId/tariffs/test/:tariffId" element={<LocationTariffTesting />} />
        <Route path="/:locationId/tariffs/:tariffId" element={<LocationTariffDetails />} />
        <Route path="/:locationId/groups/new" element={<LocationGroupDetails />} />
        <Route path="/:locationId/groups/:groupId" element={<LocationGroupDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </RequireRole>
  )
}

export {
  LocationsPage
}
