import React, { ReactElement } from 'react'
import { Tag } from 'rsuite'

type Props = {
  access: string
}

function AccessTag (props: Props): ReactElement {
  const { access } = props

  if ((access === 'SUBSCRIPTION') || (access === 'MEMBER')) {
    return (<Tag color="blue">{access}</Tag>)
  }
  if (access === 'CASUAL') {
    return (<Tag color="green">{access}</Tag>)
  }
  if (access === 'ORGANISATION') {
    return (<Tag color="cyan">{access}</Tag>)
  }
  if (access === 'ALLOWED') {
    return (<Tag color="orange">{access}</Tag>)
  }
  if (access === 'ACCESSLIST') {
    return (<Tag color="orange">{access}</Tag>)
  }
  return <Tag>{access}</Tag>
}

export {
  AccessTag
}
