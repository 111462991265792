import React from 'react'
import { Field, useField } from 'formik'
import { Radio, Panel } from 'rsuite'
import { Input, SelectPicker, RadioGroup } from '@rsuite/formik'

import { useParams } from 'react-router-dom'

import FieldWithFormik from '../formik-fields/FieldWithFormik'
import { useApi } from '../../app/hooks'
import { getLocation } from '../../services/graphql/queries'

export function TariffNamePage (
  { form, groups, organisations }: { form: any, groups: any, organisations: any }
) {
  const { locationId } = useParams()

  const locationQuery = useApi(getLocation)
  const locationData = locationQuery.getResponse()

  React.useEffect(() => {
    if (locationId) {
      locationQuery.sendRequest({ locationId })
    }
    return () => {
      locationQuery.cleanup()
    }
  }, [])

  const cameraGroups = React.useMemo(
    () => {
      if (!locationData.data || !locationData.data.cameraGroups) {
        return []
      }
      return locationData.data.cameraGroups.map(({ name }) => ({ label: name, value: name }))
    },
    [locationData]
  )

  const { setFieldValue, errors, touched } = form
  const [tariffAssignmentTypeField] = useField('tariffAssignmentType')
  const radioHandler = () => {
    setFieldValue('group', null)
    setFieldValue('organisation', null)
  }

  const organisationGroups: {label: string, value: string, role: string}[] = []
  organisations.forEach((org: any) => {
    if (org.groups) {
      org.groups.forEach((
        g: any
      ) => {
        organisationGroups.push({ label: g.name, value: `${org.id}:${g.id}`, role: org.name })
      })
    }
  })

  const { value } = tariffAssignmentTypeField
  let picker = null
  if (value === 'group') {
    picker = (
      <FieldWithFormik
        errors={errors}
        touched={touched}
        name="group"
        label="Select the car park group to assign the tariff to:"
        data={groups}
        component={SelectPicker}
        required
      />
    )
  }
  if (value === 'organisation') {
    picker = (
      <FieldWithFormik
        errors={errors}
        touched={touched}
        name="organisation"
        label="Select the organisation group to assign the tariff to:"
        data={organisationGroups}
        groupBy="role"
        component={SelectPicker}
        required
      />
    )
  }
  return (
    <>
      <Panel header="Tariff details">
        <FieldWithFormik
          errors={errors}
          touched={touched}
          required
          name="name"
          label="Enter a unique name for the tariff:"
          component={Input}
        />

        <FieldWithFormik
          required
          errors={errors}
          touched={touched}
          name="invoiceType"
          label="Select the payment type:"
          data={[
            { label: 'At gate', value: 'at-gate' },
            { label: 'Pre paid', value: 'pre-paid' },
            { label: 'Post paid', value: 'post-paid' },
          ]}
          searchable={false}
          component={SelectPicker}
        />

        <FieldWithFormik
          errors={errors}
          touched={touched}
          name="cameraGroup"
          label="Assign to a camera group:"
          data={cameraGroups}
          component={SelectPicker}
        />
      </Panel>
      <Panel header="Assign tariff to users or groups">
        <p>
          The tariff will apply to:
        </p>
        <Field name="tariffAssignmentType" component={RadioGroup} onChange={radioHandler}>
          <Radio value="all">All users</Radio>
          <Radio value="member">All members</Radio>
          <Radio value="group">Car park group</Radio>
          <Radio value="organisation">Organisation group</Radio>
        </Field>
        {picker}
      </Panel>
    </>
  )
}
